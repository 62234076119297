.header {
  width: 100%;
  height: 5rem;
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
  align-items: center;
  background-color: #008080;
}

.logo {
  font-size: 2rem;
  color: white;
}

.nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav li {
  margin-left: 1.5rem;
  font-size: 1.25rem;
}

.nav a {
  text-decoration: none;
  color: #88dfdf;
}

.nav a:hover,
.nav a:active,
.nav a.active {
  color: #e6fcfc;
}

.logoutButton {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.25rem;
  color:#e6fcfc
}

.logoutIcon {
  width: 20px; /* Adjust the size of the image icon */
  height: 20px;
  margin-right: 8px; /* Adjust the spacing between the icon and the text */
}