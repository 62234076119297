.article-preview-5sites {
  width: fit-content;
  margin: 0 auto;
}
.post-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 40vw;
  // background-color: red;
}

.post-headline {
  height: auto;
  width: inherit;
  background-repeat: no-repeat;
}

.post-title {
  height: auto;
  color: black;
  width: inherit;
  text-align: left;
  font-size: 40px;
  margin: 0 auto;
}

.show-sections {
  // margin-top: 3rem;
}

//TEMPLATE MANAGEMENT

.template-1 {
  // background-color: red;
  margin-top: 2rem;
  width: 40vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin: 0 auto;
  // margin-top: 2rem;
  // margin-bottom: 2rem;

  .post-subtitle {
    width: auto;
    align-self: flex-start;
    text-align: left;
    // background-color: red;
    // font-size: 34px;
    // margin-bottom: 2rem;
    color: #4e4d4d;
  }

  .slide-image {
    // display: flex;
    width: 20vw;
  }

  .article-caption {
    margin-left: 1.5rem;
    font-style: italic;
    font-size: 13.5px;
    color: #4e4d4d;
  }

  img {
  }

  .post-image {
    width: 17vw;
    margin: 0 auto;
    margin-left: 1.5rem;
    border-radius: 3px;
  }

  .post-parr {
    margin-bottom: 0;
    text-align: justify;
    align-self: flex-start;
  }
}

.template-2 {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-bottom: 2rem;
  margin-top: 0px;

  .article-caption {
    font-style: italic;
    font-size: 13.5px;
    color: #4e4d4d;
  }

  .post-image {
    margin-top: 0rem;
    width: 40vw;
    max-width: 80vw;
    // max-height: 60vh;
    object-fit: cover;
    border-radius: 0px;
  }

  .slide-image {
    width: auto;
    text-align: center;
    margin-bottom: 2rem;
  }

  //OJO
  .image-caption {
    margin-left: 4.9rem;
  }

  .post-subtitle {
    margin-top: 4rem;
    order: 0;
    font-size: 32px;
    text-align: left;
    color: #000;
  }

  .post-parr {
    margin-top: 0px;
    text-align: justify;
    width: auto;
    line-height: 1.7rem;
    margin-bottom: 0rem;
  }

  p {
    order: 2;
  }
}

.template-3 {
  margin: 0 auto;
  text-align: center;
  color: #000;
  margin-bottom: 0px;

  .article-caption {
    font-style: italic;
    font-size: 13.5px;
    color: #4e4d4d;
  }

  .post-subtitle {
    margin-top: 4rem;
    //   margin-bottom: -2.5rem;
    order: 0;
    font-size: 40px;
    text-align: center;
    color: #000;
    width: inherit;
  }

  .post-parr {
    line-height: 1.7rem;
    text-align: justify;
    width: auto;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }

  .slide-image {
    width: auto;
    height: auto;
  }

  .post-image {
    margin-top: 0rem;
    width: 40vw;
    // max-height: 60vh;
    object-fit: cover;
    border-radius: 0px;
  }

  p {
    order: 0;
    flex: 1;
  }
}

img {
  width: auto;
}

.slide-image {
  position: relative;
  margin: 0 auto;
  width: auto;
}

.image-caption {
  position: absolute;
  bottom: 2.5em;
  width: auto;
  // padding-top: .5em;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ebebeb;
  left: 2em;
  height: 34px;
  overflow: hidden;
  transition: all 0.4s ease-out;
}

.image-caption:before {
  content: "+";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  height: 40px;
  width: 35px;
  text-align: center;
  line-height: 40px;
  font-size: 2em;
  font-weight: bold;
  transition: all 0.4s ease-out;
}

.image-caption:hover:before {
  content: "-";
  top: 0;
}

.image-caption:hover {
  height: 70px;
}

.image-caption {
  margin: 0;
  padding: 0 4em 0 1em;
  font-style: italic;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .post-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .post-headline {
    margin-top: 80px !important;
    height: auto;
    width: 85vw;
  }

  // .background-mainPhoto {
  //   width: 85vw;
  //   height: 35vh;
  //   position: absolute;
  //   z-index: 1;

  //   object-fit: cover;
  //   filter: brightness(63%) blur(1.1px) opacity(98%);
  // }

  .post-title {
    width: 90vw !important;
  }

  .text-written {
    width: 85vw !important;
  }

  //TEMPLATE MANAGEMENT

  .template-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85vw;
    margin: 0 auto;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    .post-subtitle {
    //   width: 250vw;
      align-self: flex-start;
      // background-color: red;
      // font-size: 34px;
      margin-bottom: 2rem;
      color: #4e4d4d;
    }

    .slide-image {
      // display: flex;
      width: auto !important;
      margin: 0 auto !important;
    }

    .article-caption {
      margin-left: 1.5rem;
      font-style: italic;
      font-size: 13.5px;
      color: #4e4d4d;
    }

    img {
    }

    .post-image {
      margin-left: 0px !important;
      width: 80vw !important;
    }

    .post-parr {
      margin: 0 auto;
      text-align: justify;
      width: 80vw;
      font-size: 13.5px;
    }

    p {
      // color: red;
      // margin: 2rem;
    }
  }

  .template-2 {
    // margin: 0 auto;
    // text-align: center;
    width: 85vw;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: center;

    .article-caption {
      margin-left: 0rem;
      text-align: left;
      font-style: italic;
      font-size: 10px;
      color: #4e4d4d;
    }

    h3 {
    }

    .post-image {
      margin-left: 0px !important;
      width: 80vw !important;
      object-fit: cover !important;
    }

    .slide-image {
      width: 80vw;
      text-align: center;
      margin-bottom: 2rem;
    }

    //OJO
    .image-caption {
      // margin-left: 4.9rem;
    }

    .post-subtitle {
      padding-top: 8rem;
      width: 80vw;
      margin: 0 auto;
      // background-color: red;
      // height: 10vh;
      font-size: 18px;
      color: #4e4d4d;

      // text-decoration: underline #4FA2B5 2px 10px;

      // background-color: red;
    }

    .post-parr {
      text-align: justify;
      width: 80vw;
      font-size: 13.5px;
    }

    caption {
    }

    p {
      order: 2;
      // color: green;
      // text-align: left;
    }
  }

  .template-3 {
    margin: 0 auto;
    text-align: center;
    width: 85vw;
    margin-top: 2rem;
    margin-bottom: 2rem;

    .article-caption {
      margin-left: 0rem;
      font-style: italic;
      font-size: 10px;
      color: #4e4d4d;
    }

    // display: flex;

    .post-subtitle {
      padding-top: 8rem;
      width: 80vw;
      margin: 0 auto;
      // background-color: red;
      // height: 10vh;
      font-size: 18px;
      color: #4e4d4d;
    }

    .post-parr {
      margin: 0 auto;
      text-align: justify;
      width: 80vw;
      font-size: 13.5px;
      margin-bottom: 1.2rem;
    }

    .slide-image {
      height: fit-content !important;
      width: 80vw !important;
      max-width: 80vw !important;
      // width: fit-content !important;
      // position: relative !important;
      // margin: 0 auto !important;
      // height: fit-content !important;
    }

    .post-image {
      width: 80vw !important;
      object-fit: cover !important;
      height: 30vh;
      max-width: none !important;
      // position: relative;
      // width: 100vw !important;
      // padding: 1rem ;
    }

    // img {
    //   position: absolute;
    //   z-index: 9;
    //   top: -270px;
    // }

    // .image-caption {
    //   z-index: 10;
    //   left: 350px;
    //   top: -35px;
    // }

    p {
      order: 0;
      flex: 1;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
